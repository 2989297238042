import React from 'react';

export const ArrowDown = {
    viewBox: '0 0 16 16',

    path: (
        <path
            d="M13.707 9.707l-5 5a.999.999 0 01-1.414 0l-5-5a.999.999 0 111.414-1.414L7 11.586V2a1 1 0 012 0v9.586l3.293-3.293a.997.997 0 011.414 0 .999.999 0 010 1.414z"
            fill="currentColor"
        />
    ),
};
