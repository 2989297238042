import React from 'react';

export const ArrowUp = {
    viewBox: '0 0 16 16',

    path: (
        <path
            d="M13.707 6.293l-5-5a.999.999 0 00-1.414 0l-5 5a.999.999 0 101.414 1.414L7 4.414V14a1 1 0 002 0V4.414l3.293 3.293a.997.997 0 001.414 0 .999.999 0 000-1.414z"
            fill="currentColor"
        />
    ),
};
