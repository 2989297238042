import React from 'react';

// Icon Source: https://thenounproject.com/term/mask/3948583/
export const Mask = {
    viewBox: '0 0 100 100',

    path: (
        <path
            fill="currentColor"
            d="M50 75.12c-9.61 0-18.16-4.62-23.56-11.75-1.04.21-2.43.08-4.2-.96-6.18-3.62-13.6-16.18-13.6-21.34 0-3.36 1.74-6.38 4.65-8.06 2.35-1.36 5.07-1.63 7.57-.8.17-.2.4-.35.66-.43 18.63-6.04 38.33-6.04 56.97 0 .26.08.49.24.66.43 2.5-.83 5.22-.56 7.57.8 2.91 1.69 4.65 4.7 4.65 8.06 0 5.16-7.41 17.73-13.57 21.34-1.79 1.05-3.19 1.17-4.23.94C68.17 70.5 59.61 75.12 50 75.12zM28.53 61.14C33.35 67.79 41.18 72.12 50 72.12c8.87 0 16.73-4.38 21.55-11.08.03-.09.07-.17.11-.25.1-.17.22-.32.37-.43 2.83-4.22 4.49-9.3 4.49-14.75V34.3c-17.38-5.4-35.67-5.4-53.04 0V45.6c0 5.43 1.64 10.48 4.44 14.68.18.13.34.3.46.5.07.12.12.24.15.36zm50.99-25.86V45.6c0 5.28-1.4 10.25-3.84 14.54.21-.1.41-.2.59-.31 5.1-2.99 12.09-14.53 12.09-18.76 0-2.28-1.18-4.32-3.15-5.47a6.256 6.256 0 0 0-5.69-.32zm-61.57-.53c-1.09 0-2.17.29-3.16.86a6.262 6.262 0 0 0-3.15 5.47c0 4.21 7.01 15.75 12.12 18.75.17.1.35.2.54.29a29.35 29.35 0 0 1-3.82-14.51V35.28a6.21 6.21 0 0 0-2.53-.53zM50 63.08c-4.21 0-8.43-.79-12.44-2.37L36 60.09c-.77-.3-1.15-1.17-.85-1.95.3-.77 1.17-1.15 1.95-.85l1.56.62a30.847 30.847 0 0 0 22.68 0l1.56-.62c.77-.3 1.64.08 1.95.85.3.77-.08 1.64-.85 1.95l-1.56.62A33.928 33.928 0 0 1 50 63.08zm13.45-11.67H37.52c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5h25.93c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5zm0-8.84c-.18 0-.37-.03-.55-.1l-1.56-.62a30.847 30.847 0 0 0-22.68 0l-1.56.62a1.505 1.505 0 0 1-1.1-2.8l1.56-.62a33.833 33.833 0 0 1 24.88 0l1.56.63c.77.3 1.15 1.17.85 1.95-.23.58-.8.94-1.4.94z"
        />
    ),
};
