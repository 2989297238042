import React from 'react';

export const CircleUp = {
    viewBox: '0 0 16 16',

    path: (
        <>
            <path
                d="M0 8a8 8 0 1016 0A8 8 0 000 8zm14.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                fill="currentColor"
            />
            <path
                d="M11.043 10.457l1.414-1.414L8 4.586 3.543 9.043l1.414 1.414L8 7.414z"
                fill="currentColor"
            />
        </>
    ),
};
