import React from 'react';

export const Location = {
    viewBox: '0 0 16 16',

    path: (
        <>
            <path
                d="M8 0a5 5 0 00-5 5c0 5 5 11 5 11s5-6 5-11a5 5 0 00-5-5zm0 8a3 3 0 110-6 3 3 0 010 6z"
                fill="currentColor"
            />
        </>
    ),
};
